<template>
    <div class="wrong">
      <Empty title="暂无数据" v-if="records.length == 0" />
      <div class="loadMore" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10">
        <div
          v-for="it in records"
          :key="it.id"
          class="list"
          v-show="!it.del"
          @click="toDetail(it)"
        >
          <div class="head">
            <img class="misImg" src="../../image/task/mistakes.png" />
            <p>题目所属年级：{{it.grade}}</p>
            <!-- <i class="close" @click="delList(it)"/> -->
          </div>
          <p class="time">收录于：{{it.gmtCreate}}</p>
          <p class="cn">
           {{it.title}}
          </p>
        </div>
      </div>
      <!-- <Tips :tip="msg" />
      <Confirm :tip.sync="title" :callback="sureList" /> -->
    </div>
</template>

<script>
import { formatDate, getQuery } from '../../common/util';
import Empty from '@/components/Empty';
export default {
  components: {
    Empty
  },
  data () {
      return {
        records: [],
        busy: false,
        current: 1,
        size: 10,
        title: '',
        msg: '',
        gradeList: ['其他', '一年级', '二年级', '三年级', '四年级','五年级','六年级','七年级','八年级'],
      }
  },
  mounted() {
    this.id = getQuery('id');
  },
  methods: {
    loadMore() {
      this.$http.post('/api/activity/wrongActivityRequest', {
        size: this.size,
        current: this.current,
        activityId: this.id
      })
      .then((res) => {
        let data = res.data.data;
        if (this.records.length > 0 && data.records.length === 0) {
          this.busy = true;
          return;
        };
        this.records = this.records.concat(data.records);
        this.current ++;
      }).catch((err) => {
          this.validErrorInfo = err.errorMsg;
      })
    },
    toDetail(it) {
      sessionStorage.setItem('wrongInfo', JSON.stringify(it));
      setTimeout(() => {
        this.$router.push('/wrongDetail')
      })
    },
  }

}
</script>

<style lang="less" scoped>
    .wrong {
      min-height: 100vh;
      background-color: #f4f6f6;
      .loadMore {
        height: 100vh;
        overflow: scroll;
      }
      .list {
        padding: 0.3rem;
        box-sizing: border-box;
        background-color: white;
        margin-bottom: 0.25rem;
        .head {
          display: flex;
          position: relative;
          .close {
            position: absolute;
            right: 0.3rem;
            top: 50%;
            transform: translateY(-50%);
            background-image: url('../../image/task/close.png');
            background-size: 100% 100%;
            width: 0.32rem;
            height: 0.32rem;
          }
          .misImg {
            width: 0.45rem;
            height: 0.45rem;
          }
          p {
            font-size: 0.32rem;
            margin-left: 0.2rem;
            width: 5.5rem;
          }
        }
        .time {
          color: #999999;
          margin-top: 0.1rem;
          font-size: 0.26rem;
        }
        .cn {
          font-size: 0.32rem;
          margin-top: 0.15rem;
        }
      }
    }
</style>
